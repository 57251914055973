
import React from 'react';
import './Portfolio.css';

const Portfolio = () => {
  const portfolioItems = [
    { id: 1, title: 'Sem Culpa', image: '/images/portfolio/1.png' },
    { id: 2, title: 'Projeto 2', image: '/images/portfolio/2.png' },
    { id: 3, title: 'Projeto 3', image: '/images/portfolio/3.png' },
    { id: 4, title: 'Natalia Beauty', image: '/images/portfolio/4.png' },
    { id: 5, title: 'Tx Caps', image: '/images/portfolio/5.png' },
    { id: 6, title: 'Avvant Pay', image: '/images/portfolio/6.png' },
    { id: 7, title: 'Finance X Capital', image: '/images/portfolio/7.png' },
    { id: 8, title: 'Tx Burn', image: '/images/portfolio/8.png' },
    { id: 9, title: 'Glint Comunicações', image: '/images/portfolio/9.png' },
  ];

  return (
    <div className="portfolio-container">
      {portfolioItems.map(item => (
        <div key={item.id} className="portfolio-item">
          <img src={item.image} alt={item.title} />
          <h3>{item.title}</h3>
        </div>
      ))}
    </div>
  );
};

export default Portfolio;
